import { useCallback, useEffect, useState } from "react";
let VARIABLE_NAME = "--vh", getActualVh = ()=>Number((0.01 * window.innerHeight).toFixed(2)), count = 0;
export const useVH = ()=>{
    let [vh, setVh] = useState(0), updateVh = useCallback(()=>{
        let newVh = getActualVh();
        document.documentElement.style.setProperty(VARIABLE_NAME, "" + newVh + "px"), setVh(newVh);
    }, [
        setVh
    ]);
    return useEffect(()=>("undefined" != typeof window && (count++, updateVh(), window.addEventListener("resize", updateVh)), ()=>{
            window.removeEventListener("resize", updateVh), 0 == --count && document.documentElement.style.removeProperty(VARIABLE_NAME);
        }), [
        updateVh
    ]), vh;
};
